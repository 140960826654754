import router from '@/router'

// TODO: Portar essa função para algum tipo de ArraysUtils
const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b)

const state = () => ({
  products: [],
  params: {
    pageSize: 20,
  },
  paramsChangesCounter: 0,
  userInteract: false,
  orderBy: 'buys',
})

const actions = {
  /* Products */
  clearFilterParams({ commit, dispatch }) {
    commit('CLEAR_PRODUCTS_PARAMS', dispatch)
    commit('REGISTER_INTERACT')
  },
  setProductsData({ commit }, products) {
    commit('SET_PRODUCTS_DATA', products)
  },
  refreshProductsParams({ commit, rootGetters }) {
    commit('SET_PRODUCTS_PARAMS', rootGetters)
  },

  /* Paginator */
  changeCurrentPage({ commit, dispatch }, pageNumber) {
    dispatch('paginator/changeCurrentPage', pageNumber, { root: true })
    commit('REGISTER_INTERACT')
  },

  /* Brands */
  toggleBrandState({ commit, dispatch }, brandId) {
    dispatch('brands/toggleBrandState', brandId, { root: true })
    dispatch('paginator/changeCurrentPage', 1, { root: true })
    commit('REGISTER_INTERACT')
  },

  clearSelectedBrands({ commit, dispatch }) {
    dispatch('brands/clearSelected', false, { root: true })
    dispatch('paginator/changeCurrentPage', 1, { root: true })
    commit('REGISTER_INTERACT')
  },

  /* Subjects */
  toggleSubjectState({ commit, dispatch }, subjectId) {
    dispatch('subjects/toggleSubjectState', subjectId, { root: true })
    dispatch('paginator/changeCurrentPage', 1, { root: true })
    commit('REGISTER_INTERACT')
  },

  clearSelectedSubjects({ commit, dispatch }) {
    dispatch('subjects/clearSelected', false, { root: true })
    dispatch('paginator/changeCurrentPage', 1, { root: true })
    commit('REGISTER_INTERACT')
  },

  /* Traders */
  toggleTraderState({ commit, dispatch }, traderId) {
    dispatch('traders/toggleTraderState', traderId, { root: true })
    dispatch('paginator/changeCurrentPage', 1, { root: true })
    commit('REGISTER_INTERACT')
  },

  clearSelectedTraders({ commit, dispatch }) {
    dispatch('traders/clearSelected', false, { root: true })
    dispatch('paginator/changeCurrentPage', 1, { root: true })
    commit('REGISTER_INTERACT')
  },

  /* Categories  */
  selectCategory({ commit, dispatch }, category) {
    dispatch('categories/selectCategory', category, { root: true })
    dispatch('paginator/changeCurrentPage', 1, { root: true })
    commit('REGISTER_INTERACT')
  },

  unselectCategory({ commit, dispatch }, categoryId) {
    dispatch('categories/unselectCategory', categoryId, { root: true })
    dispatch('paginator/changeCurrentPage', 1, { root: true })
    commit('REGISTER_INTERACT')
  },

  clearSelectedCategories({ commit, dispatch }) {
    dispatch('categories/clearSelectedCategories', false, { root: true })
    dispatch('paginator/changeCurrentPage', 1, { root: true })
    commit('REGISTER_INTERACT')
  },

  /* Search String  */
  clearSearchString({ commit, dispatch }) {
    dispatch('searchBar/clearSearchString', false, { root: true })
    dispatch('paginator/changeCurrentPage', 1, { root: true })
    commit('REGISTER_INTERACT')
  },

  doSearchByString({ commit, dispatch, rootGetters }) {
    rootGetters['searchBar/getSearchString'] != ''
      ? dispatch('searchBar/doSearch', false, { root: true })
      : dispatch('searchBar/clearSearchString', false, { root: true })
    dispatch('paginator/changeCurrentPage', 1, { root: true })
    commit('REGISTER_INTERACT')
  },

  /* Ordenator  */
  changeOrderBy({ commit }, orderBy) {
    commit('CHANGE_ORDER_BY', orderBy)
    commit('REGISTER_INTERACT')
  },
}

const mutations = {
  SET_PRODUCTS_DATA(state, products) {
    state.products = []
    state.products = products
  },

  CLEAR_PRODUCTS_PARAMS(state, dispatch) {
    state.paramsChangesCounter = 0
    state.orderBy = 'buys'
    dispatch('paginator/clearPaginatorState', false, { root: true })
    dispatch('categories/clearSelectedCategories', false, { root: true })
    dispatch('brands/clearSelected', false, { root: true })
    dispatch('subjects/clearSelected', false, { root: true })
    dispatch('traders/clearSelected', false, { root: true })
    dispatch('searchBar/clearSearchString', false, { root: true })
  },

  SET_PRODUCTS_PARAMS(state, rootGetters) {
    state.paramsChangesCounter = 0

    let currentPage = rootGetters['paginator/getCurrentPage']
    if (state.params.currentPage != currentPage) {
      state.params.currentPage = currentPage
      state.paramsChangesCounter++
    }

    let sortBy = rootGetters['products/getOrderBy']
    if (state.params.sortBy != sortBy) {
      state.params.sortBy = sortBy
      state.paramsChangesCounter++
    }

    let categories = rootGetters['categories/getSelectedCategoryIds']
    if (!equals(state.params.categories, categories)) {
      state.params.categories = categories
      state.paramsChangesCounter++
    }

    let brands = rootGetters['brands/getSelectedBrandIds']
    if (!equals(state.params.brands, brands)) {
      state.params.brands = brands
      state.paramsChangesCounter++
    }

    let subjects = rootGetters['subjects/getSelectedSubjectIds']
    if (!equals(state.params.subjects, subjects)) {
      state.params.subjects = subjects
      state.paramsChangesCounter++
    }

    let tradeRepresentatives = rootGetters['traders/getSelectedTraderIds']
    if (!equals(state.params.tradeRepresentatives, tradeRepresentatives)) {
      state.params.tradeRepresentatives = tradeRepresentatives
      state.paramsChangesCounter++
    }

    let query = rootGetters['searchBar/getSearchString']
    if (state.params.query != query) {
      state.params.query = query
      state.paramsChangesCounter++
    }
  },

  CHANGE_ORDER_BY(state, orderBy) {
    state.orderBy = orderBy
  },
  REGISTER_INTERACT(state) {
    //if (!router.currentRoute.value.fullPath.startsWith('/products')) {
    if (router.currentRoute.value.fullPath != '/products') {
      router.push('/products')
    }
    state.userInteract = !state.userInteract
  },
}

const getters = {
  getOrderBy: (state) => {
    return state.orderBy
  },
  getProducts: (state) => {
    return state.products
  },
  getParams: (state) => {
    return state.params
  },
  getParamsChangesCounter: (state) => {
    return state.paramsChangesCounter
  },
  getUserInteract: (state) => {
    return state.userInteract
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
