import { createStore } from 'vuex'

import * as getters from '@/store/getters'
import * as actions from '@/store/actions.js'
import * as mutations from '@/store/mutations'

import genericColection from '@/store/modules/genericColection.js'
import toaster from '@/store/modules/toaster.js'
import session from '@/store/modules/session.js'
import paginator from '@/store/modules/paginator.js'
import products from '@/store/modules/products.js'
import brands from '@/store/modules/brands.js'
import subjects from '@/store/modules/subjects.js'
import traders from '@/store/modules/traders.js'
import categories from '@/store/modules/categories.js'
import searchBar from '@/store/modules/searchBar.js'
import rfp from '@/store/modules/rfp.js'
import bookmarks from '@/store/modules/bookmarks.js'
import documents from '@/store/modules/documents.js'

const state = {
  updatePending: 0,
}

export default createStore({
  modules: {
    genericColection,
    toaster,
    session,
    paginator,
    products,
    brands,
    subjects,
    traders,
    categories,
    searchBar,
    rfp,
    bookmarks,
    documents,
  },
  state,
  getters,
  mutations,
  actions,
})
