<template>
  <div>
    <g-dialog-root />
    <router-view />
  </div>
</template>

<script>
import { GDialogRoot } from 'gitart-vue-dialog'

export default {
  components: {
    GDialogRoot,
  },
  async created() {
    await this.$store.dispatch('session/recoverSession')
  },
}
</script>

<style></style>
