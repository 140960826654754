const state = () => ({
  subjects: [],
})

const actions = {
  setSubjectsData({ commit }, subjects) {
    if (!Array.isArray(subjects)) {
      subjects = []
    }

    subjects.forEach(function (subject) {
      subject.selected = false
    })
    commit('LOAD_SUBJECTS_DATA', subjects)
  },
  toggleSubjectState({ commit }, subjectId) {
    commit('TOGGLE_SUBJECT_STATE', subjectId)
  },
  clearSelected({ commit }) {
    commit('CLEAR_SELECTED_SUBJECTS')
  },
}

const mutations = {
  CLEAR_SELECTED_SUBJECTS(state) {
    state.subjects.forEach(function (subject) {
      subject.selected = false
    })
  },
  LOAD_SUBJECTS_DATA(state, subjects) {
    state.subjects = []
    state.subjects = subjects
  },
  TOGGLE_SUBJECT_STATE(state, subjectId) {
    let objIndex = state.subjects.findIndex((obj) => obj.id == subjectId)
    state.subjects[objIndex].selected = !state.subjects[objIndex].selected
  },
}

const getters = {
  getSubjects: (state) => {
    return state.subjects
  },
  getSubjectsBySearch: (state) => (search) => {
    let searchStr = search.trim().toLowerCase()
    if (searchStr === '') return state.subjects
    return state.subjects.filter(
      (subject) => subject.name.toLowerCase().indexOf(searchStr) > -1
    )
  },
  getSelectedSubjects: (state) => {
    return state.subjects.filter((subject) => subject.selected)
  },
  getSelectedSubjectIds: (state) => {
    return state.subjects
      .filter((subject) => subject.selected)
      .map((subject) => {
        if (subject.selected) return subject.id
      })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
