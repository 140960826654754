<template>
  <g-dialog v-model="dialogState" max-width="500">
    <div class="confirm-dialog-styled">
      <div class="confirm-dialog-styled__content">
        <div class="confirm-dialog-styled__title">
          <span class="float-end">
            <a href="#" @click.prevent="dialogState = false"
              ><span class="ci-close fs-5 mt-n1 text-danger"></span>
            </a>
          </span>
          O que deseja fazer com seu carrinho atual?
        </div>

        <p>
          O carrinho atual possui produtos do representante de fabricantes
          <span class="text-info">{{ currentTrader }}</span
          >, e o produto que você está inserindo pertence ao representante de
          fabricantes <span class="text-danger">{{ newTrader }}</span
          >.
        </p>

        <p>
          Como não é possível manter produtos de representantes diferentes em um
          mesmo carrinho, solicitamos que você decida o que pretente fazer ao
          carrinho atual:
        </p>

        <p>
          Se você optar por
          <button class="btn btn-sm btn-danger" disabled>Limpar</button>
          o carrinho atual será <span class="text-danger">excluído</span>!
        </p>

        <p>
          Se você optar por
          <button class="btn btn-sm btn-info" disabled>
            Guardar e criar novo
          </button>
          o carrinho atual será guardado e você poderá acessá-lo em "Meus
          Carrinhos"!
        </p>
      </div>

      <div class="confirm-dialog-styled__actions pe-0">
        <button class="btn btn-info me-2" @click="store">
          Guardar e criar novo
        </button>
        <button class="btn btn-danger me-2" @click="clear">Limpar</button>
        <button class="btn btn-accent me-2" @click="dialogState = false">
          Cancelar
        </button>
      </div>
    </div>
  </g-dialog>
</template>

<script>
import { apiGetData } from '@/services/ApiService.js'
import { mapActions } from 'vuex'

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      required: true,
    },
    requestId: {
      type: Number,
      required: true,
    },
    currentTrader: {
      type: String,
      required: true,
    },
    newTrader: {
      type: String,
      required: true,
    },
  },
  emits: ['update:model-value', 'update:dialog-response'],
  computed: {
    dialogState: {
      get() {
        return this.modelValue
      },

      set(value) {
        this.$emit('update:model-value', value)
      },
    },
  },

  methods: {
    ...mapActions({
      loadRfp: 'rfp/loadRfp',
      addToRfp: 'rfp/addToRfp',
    }),

    clear() {
      return apiGetData('store/rfp/clean').then((response) => {
        if (response.success) {
          //this.loadRfp()
          this.addToRfp({
            vm: this,
            product: this.product,
          })

          this.dialogState = false
        }
      })
    },
    store() {
      apiGetData(`store/rfp/downgrade/${this.requestId}`).then((response) => {
        if ('success' in response && response.success) {
          this.addToRfp({
            vm: this,
            product: this.product,
          })

          this.dialogState = false
        }
      })
    },
  },
}
</script>

<style scoped>
.confirm-dialog-styled__content {
  padding: 20px;
  padding-top: 0;
}

.confirm-dialog-styled__title {
  font-size: 30px;
  font-weight: 200;
  margin-bottom: 20px;
  margin-top: 10px;
}

.confirm-dialog-styled__actions {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
