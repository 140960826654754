import { apiGetData, apiPostData } from '@/services/ApiService.js'

const state = () => ({
  product: [],
})

const actions = {
  loadProductDocs({ commit }, productId) {
    apiGetData(`contents/publisher/list/STORE_PRODUCT/${productId}`).then(
      (response) => {
        commit('LOAD_PRODUCT', response)
      }
    )
  },
  productCheck({ commit }, event) {
    commit('CHECK_PRODUCT_DOC', {
      id: parseInt(event.srcElement.alt),
      status: event.srcElement.checked,
    })
  },
}

const mutations = {
  LOAD_PRODUCT(state, data) {
    state.product = data ?? []
  },
}

const getters = {
  hasProductLinks: (state, getters) => {
    return getters.getProductLinks.length
  },

  getProductLinks: (state) => {
    return state.product.filter((doc) => {
      return doc.modus == 'LINK'
    })
  },
  getProductTexts: (state) => {
    return state.product.filter((doc) => {
      return doc.modus == 'TEXT'
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
