import { apiGetData, apiPostData } from '@/services/ApiService.js'
import AddProductDialog from '@/components/dialogs/AddProductDialog.vue'

const state = () => ({
  instanceId: 0,
  requestId: 0,
  entityId: 0,
  addressId: 0,
  currentStep: 1,
  isDiferentTrader: false,
  hasTitle: false,
  label: '',
  trader: {
    id: 0,
    name: '-',
  },
  deliveryAddress: {
    flag_ddp: 1,
    floor: '',
    room: '',
    ward: '',
    flag_under_construction: 0,
    flag_loading_ramps: 0,
    flag_underground: 0,
    flag_freight_elevator: 1,
    freight_elevator_w_unit_id: 0,
    freight_elevator_d_unit_id: 0,
    freight_elevator_load_capacity: 0,
    freight_elevator_length: 0,
    freight_elevator_door_height: 0,
    freight_elevator_door_width: 0,
    flag_wall_demolition: 0,
    flag_external_lifting: 0,
    obs: '',
  },
  items: [],
  loadCount: 0,
})

const actions = {
  // General

  loadRfp({ commit }) {
    apiGetData('store/rfp/get').then((response) => {
      commit('LOAD_RFP', response)
      commit('SET_HAS_TITLE', !!('label' in response ? response.label : ''))
      commit('GO_TO_FIRST_STEP')
    })
  },

  // Items

  addToRfp({ dispatch, commit, state }, inputObj) {
    return apiPostData('store/rfp/add', inputObj.product)
      .then((response) => {
        commit('SET_HAS_DIFF_TRADER', response.isDiferentTrader)

        if (response.success) {
          dispatch('loadRfp')
        } else {
          inputObj.vm.$dialog.addDialog({
            component: AddProductDialog,
            props: {
              product: inputObj.product,
              requestId: state.requestId,
              currentTrader: response.currentTrader,
              newTrader: response.newTrader,
            },
          })
        }
        return response.success // resolve
      })
      .catch((err) => {
        throw err.response // reject
      })
  },

  deleteToRfp({ dispatch }, itemId) {
    return apiGetData('store/rfp/delete/' + itemId)
      .then((response) => {
        if (response.success) {
          dispatch('loadRfp')
        }
        return response.success // resolve
      })
      .catch((err) => {
        throw err.response // reject
      })
  },

  // Main Step

  selectInstance({ commit }, instanceId) {
    commit('CHANGE_INSTANCE_ID', instanceId)
  },

  selectEntity({ commit }, entityId) {
    commit('CHANGE_ENTITY_ID', entityId)
  },

  selectAddress({ commit }, addressId) {
    commit('CHANGE_ADDRESS_ID', addressId)
  },

  changeDeliveryAddress({ commit }, deliveryAddress) {
    commit('CHANGE_DELIVERY_ADDRESS', deliveryAddress)
  },

  sendMainStep({ dispatch, state }) {
    let payload = state.deliveryAddress
    payload.entity_id = state.entityId
    payload.address_id = state.addressId

    return apiPostData('store/rfp-steps/main-step-submit', payload)
      .then((response) => {
        if (response.success) {
          dispatch('goToNextStep')
        }
        return response.success // resolve
      })
      .catch((err) => {
        throw err.response.data // reject
      })
  },

  // Item Step

  selectItemAddress({ commit, getters }, addressId) {
    commit('CHANGE_ITEM_ADDRESS_ID', { addressId, getters })
  },

  selectItemDestination({ commit, getters }, destination) {
    commit('CHANGE_ITEM_DESTINATION', { destination, getters })
  },

  changeItemQtd({ commit, getters }, qtd) {
    commit('CHANGE_ITEM_QTD', { qtd, getters })
  },

  changeItemMessage({ commit, getters }, message) {
    commit('CHANGE_ITEM_MESSAGE', { message, getters })
  },

  changeItemDeliveryAddress({ commit, getters }, deliveryAddress) {
    commit('CHANGE_ITEM_DELIVERY_ADDRESS', { deliveryAddress, getters })
  },

  toggleExclusiveAddress({ commit, getters }) {
    commit('TOGGLE_ITEM_DELIVERY_ADDRESS', getters)
  },

  sendProductStep({ dispatch, state, getters }) {
    let payload = {
      item_id: state.items[getters.getItemIdxStep].id,
      qtd: state.items[getters.getItemIdxStep].qtd,
      message: state.items[getters.getItemIdxStep].message,
      destination: state.items[getters.getItemIdxStep].destination,
      use_exclusive_address_flag:
        state.items[getters.getItemIdxStep].use_exclusive_address_flag,
      address_id: state.items[getters.getItemIdxStep].address_id,
      delivery_address: state.items[getters.getItemIdxStep].delivery_address,
    }

    return apiPostData('store/rfp-steps/product-step-submit', payload)
      .then((response) => {
        if (response.success) {
          dispatch('goToNextStep')
        }
        return response.success // resolve
      })
      .catch((err) => {
        throw err.response.data // reject
      })
  },

  // Steps

  sendCurrentStep({ state, dispatch }) {
    if (state.currentStep == 1) {
      dispatch('sendMainStep')
    }

    if (state.currentStep > 1 && state.currentStep < state.items.length + 2) {
      dispatch('sendProductStep')
    }
  },

  resetSteps({ commit }) {
    commit('GO_TO_FIRST_STEP')
  },

  goToNextStep({ commit }) {
    commit('GO_TO_NEXT_STEP')
  },

  goToPriorStep({ commit }) {
    commit('GO_TO_PRIOR_STEP')
  },
}

const mutations = {
  // General

  LOAD_RFP(state, data) {
    state.requestId = 'request_id' in data ? data.request_id : 0
    state.trader = 'trader' in data ? data.trader : {}
    state.label = 'label' in data ? data.label : ''
    state.items = 'items' in data ? data.items : []
    state.instanceId = 'instance_id' in data ? data.instance_id : 0
    state.entityId = 'entity_id' in data ? data.entity_id : 0
    state.addressId = 'address_id' in data ? data.address_id : 0
    state.deliveryAddress =
      'delivery_address' in data ? data.delivery_address : { flag_ddp: 1 }
    state.loadCount++
  },

  SET_HAS_TITLE(state, data) {
    state.hasTitle = data
  },

  SET_HAS_DIFF_TRADER(state, data) {
    state.isDiferentTrader = data
  },

  // Main Step

  CHANGE_INSTANCE_ID(state, instanceId) {
    state.instanceId = instanceId
  },
  CHANGE_ENTITY_ID(state, entityId) {
    state.entityId = entityId
  },
  CHANGE_ADDRESS_ID(state, addressId) {
    state.addressId = addressId
  },
  CHANGE_DELIVERY_ADDRESS(state, deliveryAddress) {
    state.delivery_address = deliveryAddress
  },

  // Item Step

  CHANGE_ITEM_ADDRESS_ID(state, { addressId, getters }) {
    state.items[getters.getItemIdxStep].address_id = addressId
  },
  CHANGE_ITEM_DELIVERY_ADDRESS(state, { deliveryAddress, getters }) {
    state.items[getters.getItemIdxStep].delivery_address = deliveryAddress
  },
  CHANGE_ITEM_QTD(state, { qtd, getters }) {
    state.items[getters.getItemIdxStep].qtd = qtd
  },
  CHANGE_ITEM_MESSAGE(state, { message, getters }) {
    state.items[getters.getItemIdxStep].message = message
  },
  CHANGE_ITEM_DESTINATION(state, { destination, getters }) {
    state.items[getters.getItemIdxStep].destination = destination
  },
  CHANGE_ITEM_MESSAGE(state, { message, getters }) {
    state.items[getters.getItemIdxStep].message = message
  },
  TOGGLE_ITEM_DELIVERY_ADDRESS(state, getters) {
    state.items[getters.getItemIdxStep].use_exclusive_address_flag =
      !state.items[getters.getItemIdxStep].use_exclusive_address_flag
  },

  // Steps

  GO_TO_FIRST_STEP(state) {
    state.currentStep = 1
  },
  GO_TO_NEXT_STEP(state) {
    if (state.currentStep < state.items.length + 2) state.currentStep++
  },
  GO_TO_PRIOR_STEP(state) {
    if (state.currentStep > 1) state.currentStep--
  },
}

const getters = {
  // General

  getLabel: (state) => {
    return state.label
  },

  hasTitle: (state) => {
    return state.hasTitle
  },

  isDiferentTrader: (state) => {
    return state.isDiferentTrader
  },

  getTrader: (state) => {
    return state.trader
  },

  getLoadCount: (state) => {
    return state.loadCount
  },

  // Items

  getItems: (state) => {
    return state.items
  },

  getItem: (state, getters) => {
    return state.items[getters.getItemIdxStep]
  },

  getItemsCount: (state) => {
    return state.items.length
  },

  // Steps

  getCurrentStep: (state) => {
    return state.currentStep
  },

  getItemIdxStep: (state) => {
    let idx = state.currentStep - 2
    if (idx < 0) idx = 0
    if (idx > state.items.length) idx = state.items.length
    return idx
  },

  getTotalSteps: (state) => {
    return state.items.length + 2
  },

  getStepsPercentage(state) {
    if (state.items.length == 0) return 0
    let p = Math.floor((state.currentStep / (state.items.length + 2)) * 100)
    if (p < 0) p = 0
    if (p > 100) p = 100
    return p
  },

  // Main Step

  getInstanceId: (state) => {
    return state.instanceId
  },

  getEntityId: (state) => {
    return state.entityId
  },

  getAddressId: (state) => {
    return state.addressId
  },

  getDeliveryAddress: (state) => {
    return state.deliveryAddress
  },

  // Item Step

  // getItem: (state, getters) => {
  //   return state.items[getters.getItemIdxStep]
  // },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
