const state = () => ({
  searchStr: '',
  searchInCategory: 0,
  hasSearchActive: false,
})

const actions = {
  setSearchString({ commit }, searchStr) {
    commit('SET_SEARCH_STRING', searchStr)
  },

  setSearchInCategory({ commit }, searchInCategory) {
    commit('SET_SEARCH_IN_CATEGORY', searchInCategory)
  },

  clearSearchString({ commit }) {
    commit('CLEAR_SEARCH_STRING')
  },

  doSearch({ commit, dispatch, state }) {
    dispatch('categories/selectCategoryId', state.searchInCategory, {
      root: true,
    })
    commit('ACTIVE_SEARCH_BY_STRING')
  },
}

const mutations = {
  SET_SEARCH_STRING(state, searchStr) {
    state.searchStr = searchStr
  },
  SET_SEARCH_IN_CATEGORY(state, searchInCategory) {
    state.searchInCategory = searchInCategory
  },
  ACTIVE_SEARCH_BY_STRING(state) {
    state.hasSearchActive = true
  },
  CLEAR_SEARCH_STRING(state) {
    state.searchStr = ''
    state.searchInCategory = 0
    state.hasSearchActive = false
  },
}

const getters = {
  getSearchString: (state) => {
    return state.searchStr
  },
  getSearchInCategory: (state) => {
    return state.searchInCategory
  },
  hasSearchActive: (state) => {
    return state.hasSearchActive
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
