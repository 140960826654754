const state = () => ({
  traders: [],
})

const actions = {
  setTradersData({ commit }, traders) {
    if (!Array.isArray(traders)) {
      traders = []
    }

    traders.forEach(function (trader) {
      trader.selected = false
    })
    commit('LOAD_TRADERS_DATA', traders)
  },
  toggleTraderState({ commit }, traderId) {
    commit('TOGGLE_TRADER_STATE', traderId)
  },

  clearSelected({ commit }) {
    commit('CLEAR_SELECTED_TRADERS')
  },
}

const mutations = {
  CLEAR_SELECTED_TRADERS(state) {
    state.traders.forEach(function (trader) {
      trader.selected = false
    })
  },
  LOAD_TRADERS_DATA(state, traders) {
    state.traders = []
    state.traders = traders
  },
  TOGGLE_TRADER_STATE(state, traderId) {
    let objIndex = state.traders.findIndex((obj) => obj.id == traderId)
    state.traders[objIndex].selected = !state.traders[objIndex].selected
  },
}

const getters = {
  getTraders: (state) => {
    return state.traders
  },
  getTradersBySearch: (state) => (search) => {
    let searchStr = search.trim().toLowerCase()
    if (searchStr === '') return state.traders
    return state.traders.filter(
      (trader) => trader.name.toLowerCase().indexOf(searchStr) > -1
    )
  },
  getSelectedTraders: (state) => {
    return state.traders.filter((trader) => trader.selected)
  },
  getSelectedTraderIds: (state) => {
    return state.traders
      .filter((trader) => trader.selected)
      .map((trader) => {
        if (trader.selected) return trader.id
      })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
