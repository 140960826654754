import { createRouter, createWebHistory } from 'vue-router'
//import store from '@/store'
import NProgress from 'nprogress'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(
        /* webpackChunkName: "StoreLayout" */ '@/views/common/store/LayoutView.vue'
      ),
    children: [
      {
        path: '',
        name: 'ProductsRedirect',
        redirect: { name: 'Products' },
      },
      {
        path: 'products/:pageId?',
        name: 'Products',
        props: (route) => ({ pageId: parseInt(route.params.pageId) || 0 }),
        component: () =>
          import(
            /* webpackChunkName: "StoreProducts" */ '@/views/store/ProductsView.vue'
          ),
      },
      {
        path: 'product/:productId/:pageId?',
        name: 'Product',
        props: (route) => ({
          productId: parseInt(route.params.productId) || 0,
          pageId: parseInt(route.params.pageId) || 0,
        }),
        component: () =>
          import(
            /* webpackChunkName: "StoreProduct" */ '@/views/store/ProductView.vue'
          ),
      },
    ],
  },

  {
    path: '/error',
    name: 'Errors',
    component: () =>
      import(
        /* webpackChunkName: "PublicLayout" */ '@/views/common/public/LayoutView.vue'
      ),

    children: [
      {
        path: '503',
        name: 'Error503',
        component: () =>
          import(
            /* webpackChunkName: "Error503" */ '@/views/errors/503View.vue'
          ),
      },
      {
        path: '404',
        name: 'Error404',
        component: () =>
          import(
            /* webpackChunkName: "Error404" */ '@/views/errors/404View.vue'
          ),
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'Error404' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }

    if (savedPosition) {
      return savedPosition
    }
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
