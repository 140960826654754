const state = () => ({
  brands: [],
})

const actions = {
  setBrandsData({ commit }, brands) {
    if (!Array.isArray(brands)) {
      brands = []
    }

    brands.forEach(function (brand) {
      brand.selected = false
    })
    commit('LOAD_BRANDS_DATA', brands)
  },
  toggleBrandState({ commit }, brandId) {
    commit('TOGGLE_BRAND_STATE', brandId)
  },
  clearSelected({ commit }) {
    commit('CLEAR_SELECTED_BRANDS')
  },
}

const mutations = {
  CLEAR_SELECTED_BRANDS(state) {
    state.brands.forEach(function (brand) {
      brand.selected = false
    })
  },
  LOAD_BRANDS_DATA(state, brands) {
    state.brands = []
    state.brands = brands
  },
  TOGGLE_BRAND_STATE(state, brandId) {
    let objIndex = state.brands.findIndex((obj) => obj.id == brandId)
    state.brands[objIndex].selected = !state.brands[objIndex].selected
  },
}

const getters = {
  getBrands: (state) => {
    return state.brands
  },
  getBrandsBySearch: (state) => (search) => {
    let searchStr = search.trim().toLowerCase()
    if (searchStr === '') return state.brands
    return state.brands.filter(
      (brand) => brand.name.toLowerCase().indexOf(searchStr) > -1
    )
  },
  getSelectedBrands: (state) => {
    return state.brands.filter((brand) => brand.selected)
  },
  getSelectedBrandIds: (state) => {
    return state.brands
      .filter((brand) => brand.selected)
      .map((brand) => {
        if (brand.selected) return brand.id
      })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
