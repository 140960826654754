const state = () => ({
  selectedCategories: [],
  categories: [],
})

const findCategoryRecursively = (category, categoryId) => {
  if (category.id === categoryId) {
    return true
  }
  category.children.forEach(function (sub) {
    findCategoryRecursively(sub, categoryId)
  })
  return false
}

// const categoriesInterate = (category, selected) => {
//   selected.push(category)
//   category.children.forEach(function (sub) {
//     categoriesInterate(sub, selected)
//   })
// }

const actions = {
  setCategoriesData({ commit }, categories) {
    if (!Array.isArray(categories)) {
      categories = []
    }

    commit('LOAD_CATEGORIES_DATA', categories)
  },

  selectCategory({ commit }, category) {
    commit('SELECT_CATEGORY', category)
  },

  selectCategoryId({ commit, state }, categoryId) {
    let categoryFound = false
    let foundFlag = false

    state.categories.every(function (category) {
      foundFlag = findCategoryRecursively(category, categoryId)
      categoryFound = foundFlag ? category : false
      return !foundFlag
    })

    foundFlag
      ? commit('SELECT_CATEGORY', categoryFound)
      : commit('CLEAR_SELECTED_CATEGORIES')
  },

  unselectCategory({ commit }, categoryId) {
    commit('UNSELECT_CATEGORY', categoryId)
  },

  clearSelectedCategories({ commit }) {
    commit('CLEAR_SELECTED_CATEGORIES')
  },
}

const mutations = {
  LOAD_CATEGORIES_DATA(state, category) {
    state.categories = []
    state.categories = category
  },

  CLEAR_SELECTED_CATEGORIES(state) {
    state.selectedCategories = []
  },

  SELECT_CATEGORY(state, category) {
    state.selectedCategories = []
    //let selected = []
    //categoriesInterate(category, selected)
    state.selectedCategories.push(category)
  },

  UNSELECT_CATEGORY(state, categoryId) {
    state.selectedCategories = state.selectedCategories.filter(
      (category) => category.id != categoryId
    )
  },
}

const getters = {
  getCategories: (state) => {
    return state.categories
  },
  getSelectedCategoryIds: (state) => {
    let ids = []
    state.selectedCategories.forEach(function (category) {
      ids.push(category.id)
    })
    return ids
  },
  getSelectedCategories: (state) => {
    return state.selectedCategories
  },
  getSelectedState: (state) => (category) => {
    return state.selectedCategories.includes(category)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
